import React, { useState } from "react";
import "./Header.scss";
import { Link, useLocation, } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCartProducts, selectProductAddedToCart } from "store/cart/cartSelectors";
import { selectIsAuthenticated } from "common";
import Banner from "containers/Banner/Banner";
import { selectBanner } from "store/banner/bannerSelectors";
import { FormattedMessage } from "react-intl";
function Header() {
    var _a, _b;
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const cartProducts = useSelector(selectCartProducts);
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const [menuOpen, setMenuOpen] = useState(false);
    const [kustCommunityOpen, setKustCommunityOpen] = useState(false);
    const [nomadAcademyOpen, setNomadAcademyOpen] = useState(false);
    const IS_B2C = (((_b = (_a = window) === null || _a === void 0 ? void 0 : _a.__env__) === null || _b === void 0 ? void 0 : _b.APP_MODE) == 'B2C') ? true : false;
    const { pathname } = useLocation();
    const banner = useSelector(selectBanner);
    let showBanner = false;
    if (IS_B2C && new Date() >= new Date(banner.B2CdateFrom) && new Date() <= new Date(banner.B2CdateTo) && !banner.B2Cdisabled) {
        showBanner = true;
    }
    if (!IS_B2C && new Date() >= new Date(banner.dateFrom) && new Date() <= new Date(banner.dateTo) && !banner.disabled) {
        showBanner = true;
    }
    const getHeaderClasses = () => {
        const open = menuOpen ? 'open' : '';
        return (pathname === '/' ? `black-background ${open}` : open);
    };
    const getKustCommunityClass = () => {
        return 'header-page ' + (kustCommunityOpen ? 'panel-open' : '');
    };
    const getNomadAcademyClass = () => {
        return 'header-page ' + (nomadAcademyOpen ? 'panel-open' : '');
    };
    const location = useLocation();
    React.useEffect(() => {
        setKustCommunityOpen(false);
        setNomadAcademyOpen(false);
    }, [location]);
    console.log('showBanner', showBanner);
    return (React.createElement("div", { className: showBanner ? "banner-active" : "" },
        React.createElement(Banner, null),
        React.createElement("header", { className: `${getHeaderClasses()} k-header` },
            React.createElement("nav", { className: "nav", role: "navigation", "aria-label": "main navigation" },
                React.createElement(Link, { to: "/", className: "nav-logo", onClick: () => setMenuOpen(false) }, "\u00A0"),
                React.createElement("a", { onClick: () => setMenuOpen(!menuOpen), role: "button", className: "nav-burger", "aria-label": "menu", "aria-expanded": "false", "data-target": "navigation" }, "Menu"),
                React.createElement("div", { id: "navigation", className: "nav-menu", onClick: () => setMenuOpen(false) },
                    React.createElement("ul", { className: "nav-menu--list" },
                        React.createElement("li", null,
                            React.createElement(Link, { to: "/nos-produits", className: `${pathname === '/nos-produits' ? 'active' : ''} nav-menu--list_item`, "data-text": "Produits" },
                                React.createElement(FormattedMessage, { id: "Nos produits", defaultMessage: "Produits" }))),
                        !IS_B2C &&
                            React.createElement("li", null,
                                React.createElement(Link, { to: "/le-nuancier", className: `${pathname === '/le-nuancier' ? 'active' : ''} nav-menu--list_item` },
                                    React.createElement(FormattedMessage, { id: "Le nuancier", defaultMessage: "Nuancier" }))),
                        !IS_B2C &&
                            React.createElement("li", null,
                                React.createElement("span", { style: { cursor: 'pointer' }, className: `${nomadAcademyOpen ? 'active' : ''} nav-menu--list_item`, "data-text": "Acad\u00E9mie", onClick: () => {
                                        setKustCommunityOpen(false);
                                        setNomadAcademyOpen(!nomadAcademyOpen);
                                    } },
                                    React.createElement(FormattedMessage, { id: "Formation", defaultMessage: "Acad\u00E9mie" }))),
                        !IS_B2C && React.createElement("li", null,
                            React.createElement("span", { style: { cursor: 'pointer' }, className: `${kustCommunityOpen ? 'active' : ''} nav-menu--list_item`, "data-text": "Communaut\u00E9", onClick: () => {
                                    setKustCommunityOpen(!kustCommunityOpen);
                                    setNomadAcademyOpen(false);
                                } },
                                React.createElement(FormattedMessage, { id: "Kust Community", defaultMessage: "Communaut\u00E9" }))),
                        IS_B2C &&
                            React.createElement("li", null,
                                React.createElement("a", { href: "https://kust-fr.com", className: "nav-menu--list_item" }, "Acc\u00E8s Professionnel"))),
                    React.createElement("ul", { className: "nav-menu--list" },
                        !IS_B2C && React.createElement("li", null,
                            React.createElement(Link, { className: "nav-menu--list_item", to: "/pages/coloration-essential" },
                                React.createElement(FormattedMessage, { id: "La coloration", defaultMessage: "La coloration" }))),
                        React.createElement("li", null,
                            React.createElement(Link, { className: "nav-menu--list_item", to: "/pages/a-propos" },
                                React.createElement(FormattedMessage, { id: "Kust, c'est quoi ?", defaultMessage: "A propos" }))),
                        React.createElement("li", null,
                            React.createElement(Link, { className: "nav-menu--list_item", to: "/avis" },
                                React.createElement(FormattedMessage, { id: "Avis Clients", defaultMessage: "Avis Clients" }))),
                        React.createElement("li", null,
                            React.createElement(Link, { className: "nav-menu--list_item", to: "/pages/confidentialite" },
                                React.createElement(FormattedMessage, { id: "Confidentialit\u00E9", defaultMessage: "Confidentialit\u00E9" }))),
                        React.createElement("li", null,
                            React.createElement(Link, { className: "nav-menu--list_item", to: "/pages/cgv" },
                                React.createElement(FormattedMessage, { id: "CGV", defaultMessage: "CGV" }))),
                        React.createElement("li", null,
                            React.createElement(Link, { className: "nav-menu--list_item", to: "/pages/nous-contacter" },
                                React.createElement(FormattedMessage, { id: "Nous contacter", defaultMessage: "Nous contacter" }))))),
                React.createElement("div", { onClick: () => setMenuOpen(false), className: "nav-icons" },
                    React.createElement(Link, { to: isAuthenticated ? '/mon-compte/profile' : '/authentification', className: `nav-icons--item account-icon ${pathname === ('/authentification' || '/mon-compte/profile') ? 'active' : ''}` }, "\u00A0"),
                    React.createElement(Link, { to: "/panier", className: `nav-icons--item cart-icon ${productAddedToCart ? 'add-to-cart-animation' : ''} ${pathname === '/panier' ? 'active' : ''}`, suppressHydrationWarning: true }, cartProducts.length > 0 &&
                        React.createElement("div", { className: "cart-icon--qty" }, cartProducts.reduce((quantity, product) => { return quantity + product.quantity; }, 0))))),
            React.createElement("section", { className: `${getNomadAcademyClass()} panel panel--community` },
                React.createElement("div", { className: "panel--content" },
                    React.createElement("h2", null,
                        React.createElement("small", null, "R\u00E9v\u00EAler le secret infini des"),
                        React.createElement("b", null, "Nuances")),
                    React.createElement("p", null, "Nomad academy est une jeune acad\u00E9mie de formations d\u00E9di\u00E9e aux professionnels de la coiffure. Notre mission : vous accompagner avec des formations coiffure en ad\u00E9quation avec les nouvelles tendances mode."),
                    React.createElement("a", { href: "https://nomadacademy.fr", "data-text": "D\u00E9couvrir les formations", className: "k-cta k-cta--funky", target: "_blank" },
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "D\u00E9couvrir les formations", defaultMessage: "D\u00E9couvrir les formations" }))))),
            React.createElement("section", { className: `${getKustCommunityClass()} panel panel--university` },
                React.createElement("div", { className: "panel--content" },
                    React.createElement("h2", null,
                        React.createElement("small", null,
                            React.createElement(FormattedMessage, { id: "Aller plus loin", defaultMessage: "Aller plus loin" })),
                        React.createElement("b", null,
                            React.createElement(FormattedMessage, { id: "Ensemble", defaultMessage: "Ensemble" }))),
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "Parce que nous croyons \u00E0 la bienveillance et \u00E0 l\u2019\u00E9change, nous avons cr\u00E9\u00E9 une communaut\u00E9 unique, o\u00F9 Kusteurs partagent recettes, r\u00E9alisations mais \u00E9galement astuces. Une parole d\u00E9complex\u00E9e et d\u00E9complexante, o\u00F9 chacun exprime son art et sa vision de la profession.", defaultMessage: "Parce que nous croyons \u00E0 la bienveillance et \u00E0 l\u2019\u00E9change, nous avons cr\u00E9\u00E9 une communaut\u00E9 unique, o\u00F9 Kusteurs partagent recettes, r\u00E9alisations mais \u00E9galement astuces. Une parole d\u00E9complex\u00E9e et d\u00E9complexante, o\u00F9 chacun exprime son art et sa vision de la profession." })),
                    React.createElement("a", { href: "https://www.facebook.com/groups/kust.community/", "data-text": "Rejoindre le groupe", className: "k-cta k-cta--funky", target: "_blank" },
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "Rejoindre le groupe", defaultMessage: "Rejoindre le groupe" }))))))));
}
export default Header;
