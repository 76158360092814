import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { fetchBannerAttempt } from "store/banner/bannerActions";
import { selectBanner } from "store/banner/bannerSelectors";
import "./Banner.scss";
function Banner() {
    var _a, _b;
    const dispatch = useDispatch();
    const intl = useIntl();
    const banner = useSelector(selectBanner);
    const IS_B2C = (((_b = (_a = window) === null || _a === void 0 ? void 0 : _a.__env__) === null || _b === void 0 ? void 0 : _b.APP_MODE) == 'B2C') ? true : false;
    useEffect(() => {
        dispatch(fetchBannerAttempt());
    }, [dispatch]);
    let showBanner = false;
    if (IS_B2C && new Date() >= new Date(banner.B2CdateFrom) && new Date() <= new Date(banner.B2CdateTo) && !banner.B2Cdisabled) {
        showBanner = true;
    }
    if (!IS_B2C && new Date() >= new Date(banner.dateFrom) && new Date() <= new Date(banner.dateTo) && !banner.disabled) {
        showBanner = true;
    }
    console.log('showbanner', showBanner);
    console.log('IS_B2C', IS_B2C);
    return (React.createElement(React.Fragment, null, showBanner &&
        React.createElement("div", { className: "banner", dangerouslySetInnerHTML: { __html: IS_B2C ? banner.B2Ccontent : banner.content } })));
}
export default Banner;
